.historyTitle {
    line-height: 32px;
    padding: 0 20px 0 0;
    font-size: 18px;
}
.eachFocus {
    text-align: center;
}
.eachFocus img {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.focusInfo h2 {
    margin: 5px 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
}
.focusInfo h2:hover {
    color: #f1281e;
}
.focusInfo span {
    display: inline-block;
    line-height: 26px;
    border-radius: 13px;
    background-color: #999;
    color: #fff;
    padding: 0 10px;
    cursor: pointer;
}
.noDataBox {
    text-align: center;
}
.noDataBox img {
    height: 300px;
}
.paginationBox {
    text-align: center;
}