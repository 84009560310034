/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 用户基本信息 */
.userInfo {
    text-align: center;
    padding: 20px 10px;
    background-color: rgb(255, 247, 238);
    border-radius: 5px 5px 0 0 ;
}
.userInfo img {
    height: 64px;
    width: 64px;
    border-radius: 32px;
}
.userInfo h2 {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    margin-top: 5px;
}
.userInfo p {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 30px;
}
.userInfo span {
    display: inline-block;
    line-height: 26px;
    padding: 0 15px;
    border-radius: 13px;
    background-color: #25e245;
    cursor: pointer;
}

/* 数据分类列表 */
.dataListBox {
    border: 1px solid #efefef;
    border-top: none;
}
.dataListBox ul {
    margin: 0;
    padding: 10px 0;
    list-style: none;
}
.dataListBox ul li {
    padding-left: 70px;
    line-height: 33px;
    margin-bottom: 3px;
    cursor: pointer;
}
.dataListBox ul li:hover {
    color: #f1281e;
    background-color: #f5dedc;
}