/* 版心 */
.container {
    margin: 0 auto;
    /* overflow: hidden; */
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}

/* 讲师盒子 */
.teacherBox {
    border: 1px solid #eee;
}
.teacherBox:hover {
    box-shadow: 0 2px 10px 2px rgba(111, 111, 111, 0.3);
}
.teacherImgBox {
    width: 100%;
    height: 200px;
}
.teacherImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.teacherIntro {
    padding: 20px;
}
.teacherIntro p{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    margin: 0;
}
.isfollowBox {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.isfollowBox span {
    line-height: 26px;
    cursor: pointer;
}
.noconcerns {
    background-color: #f9c231;
    color: #fff;
    border-radius: 13px;
    padding: 0 10px;
}
.isconcerns {
    background-color: #999;
    color: #fff;
    border-radius: 13px;
    padding: 0 10px;
}
.paginationBox {
    text-align: center;
    margin-bottom: 20px;
}