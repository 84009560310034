/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 面包屑 */
.breadcrumbBox {
    font-size: 16px;
    padding: 25px 0;
    border-bottom: 1px solid #efefef;
}
.breadcrumbBox :global .ant-breadcrumb {
    font-size: 17px;
}
/* 筛选盒子样式 */
.filterBox {
    margin: 0;
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #efefef;
}
.filterAreaBox {
    height: 40px;
}
.filterAll {
    display: inline-block;
    text-align: center;
    width: 50px;
    border-radius: 13px;
    line-height: 26px;
    cursor: pointer;
}
.filterLi {
    margin-left: 10px;
    display: inline-block;
    border-radius: 13px;
    line-height: 26px;
    padding: 0 10px;
    cursor: pointer;
}
.recommendHotBox {
    margin-right: 10px;
    display: inline-block;
    border-radius: 13px;
    line-height: 26px;
    padding: 0 10px;
    cursor: pointer;
}

/* 视频盒子 */
.eachVideoBox {
    transition: all 0.3s;
}
.eachVideoBox:hover {
    box-shadow: 0 5px 10px 0 rgba(144,144,144,0.3);
}
.coverImgBox {
    position: relative;
    width: 100%;
    height: 160px;
    overflow: hidden;
    cursor: pointer;
}
.coverImgBox>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.playIconBox {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    transition: all 0.3s;
}
.playIconBox img {
    opacity: 0;
    position: absolute;
    height: 150px;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    transition: all 0.3s;
}
.coverImgBox:hover .playIconBox {
    background-color: rgba(0,0,0,0.3);
}
.coverImgBox:hover .playIconBox img {
    opacity: 0.5;
    height: 50px;
}
.videoIntro {
    padding: 20px;
}
.videoIntro h2 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
}
.tagsBox {
    white-space: nowrap;
    overflow: hidden;
    margin: 5px 0;
}
.tag {
    line-height: 30px;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 0 10px;
    margin-right: 5px;
}
/* 分页器 */
.paginationBox {
    margin: 10px 0 20px 0;
    text-align: center;
}
/* 名师推荐 */
.teachersBgc {
    background-color: #eee;
    width: 100%;
}
.teachersBox {
    width: 70%;
    padding: 20px 0;
    margin: 0 auto;
    max-width: 1500px;
    min-width: 980px;
}
.teachersBoxTitle {
    font-weight: 600;
    font-family: 'SimHei';
    margin-bottom: 20px;
}
.teachersBoxTitle span {
    font-weight: 500;
    margin-left: 15px;
    display: inline-block;
    padding: 0 10px;
    line-height: 24px;
    border-radius: 12px;
    background-color: #fff;
    font-size: 14px;
    cursor: pointer;

}
.teachersBoxTitle span:hover {
    color: #f1281e;
}
.teacherLi {
    text-align: center;
}
.teacherImgbox {
    margin: 0 auto;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    overflow: hidden;
}
.teacherImgbox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.teacherLi h2 {
    font-family: 'SimHei';
    white-space: nowrap;
    overflow: hidden;
    font-size: 17px;
    margin-top: 10px;
}
.teacherLi span {
    display: inline-block;
    line-height: 26px;
    border-radius: 13px;
    color: #fff;
    text-align: center;
    width: 70px;
    cursor: pointer;
}
.noconcerns {
    background-color: #f9c231;
}
.isconcerns {
    background-color: #999;
}