/* 轮播图 */
.carousel {
    width: 100%;
    height: 450px;
    background-color: #ddd;
}
.carousel h3{
    width: 100%;
    height: 100%;
}
.carousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* 版心 */
.container {
    margin: 0 auto;
    /* overflow: hidden; */
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}

/* 直播马上看 标题 */
.homeOnline {
    margin-top: 20px;
}
.homeTitle {
    text-align: center;
    position: relative;
    margin: 0;
}
.homeTitle span {
    line-height: 80px;
    font-size: 28px;
    color: #000;
    /* font-weight: 600; */
    font-family: 'SimHei';
    letter-spacing: 0.3em;
}
.homeTitle p {
    position: absolute;
    right: 0;
    top: 25px;
    margin: 0;
    font-size: 15px;
    cursor: pointer;
}
.homeTitle p:hover {
    color: #f1281e;
}
.homeTitle p img {
    height: 15px;
    margin: 0 0 5px 2px;
}
.homeTitle .bottomline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 3px solid orange;
    border-radius: 3px;
    width: 40px;
}
/* 直播盒子 */
.onlineLists {
    width: 100%;
    height: 400px;
    margin-top: 30px;
}
/* 直播盒子左边 */
.listLeft {
    width: 100%;
    height: 400px;
    background-size: cover;
}
.listLeftContent {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    text-align: center;
    padding-top: 30px;
}
.listLeftContent h2 {
    margin-bottom: 10px;
    line-height: 60px;
    color: #fff;
    font-size: 32px;
    letter-spacing: 0.05em;
    font-family: 'SimHei';
}
.listLeftContent p {
    margin-bottom: 30px;
    line-height: 50px;
    color: #fff;
    font-size: 26px;
    font-family: 'SimHei';
}
.listLeftContent p span {
    display: inline-block;
    line-height: 40px;
    border-radius: 20px;
    padding: 0 10px;
    font-size: 35px;
    margin: 0 5px;
    background-color: #fff;
    color: #f1281e;
}
.listLeftContent>span {
    display: inline-block;
    font-size: 36px;
    color: #fff;
    background-color: #f1281e;
    padding: 0 13px 0 15px;
    border-radius: 10px;
    letter-spacing: 0.03em;
    font-family: 'SimHei';
    cursor: pointer;
}
.onlineIntro {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: rgba(0,0,0,0.5);
} 
.onlineTeacherName {
    padding: 0 16px;
}
.onlineTeacherName img {
    height: 44px;
    margin: 0 5px 7px 0;
}
.onlineTeacherName span {
    color: #fff;
    line-height: 80px;
    font-size: 18px;
    font-family: 'SimHei';
}
.onlineTeacherOrg {
    padding-right: 10px;
    width: 100%;
    text-align: right;
    line-height: 80px;
    color: #fff;
    font-size: 18px;
    font-family: 'SimHei';
}
/* 盒子右边 */
.listRight {
    width: 100%;
    height: 400px;
    background-color: #f4f4f4;
    overflow: auto;
}
.listRight :global .ant-timeline-item {
    margin: 0;
    padding: 20px 0 0 20px;
    border-left: 3px solid orange;
    height: 50px;
    cursor: pointer;
    font-size: 18px;
    font-family: 'SimHei';
    white-space: nowrap;
    overflow: hidden;
}
.listRight :global .ant-timeline-item:hover {
    background-color: #fff;
}
.listRight :global .ant-timeline-item-head {
    top: 20px;
}
/* .listRight :global .ant-timeline-item-last {
    height: 50px;
} */

/* 功能 */
.funcLiBox {
    padding-top: 20px;
    width: 100%;
    text-align: center;
}
.funcLiBox img {
    height: 80px;
    cursor: pointer;
}
.funcLiBox h2 {
    margin-top: 10px;
    cursor: pointer;
}
/* 文章列表 */
.articalCateBox ul {
    list-style: none;
}
.articalCateBox ul li {
    position: relative;
    display: inline-block;
    margin: 0 20px;
    line-height: 40px;
    font-size: 16px;
    cursor: pointer;
}
.articalCateBox ul li:last-child:hover {
    color: #f1281e;
}
.artCateBottomline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid #f1281e;
    border-radius: 2px;
    width: 30px;
}
.articalContentBox {
    margin: 20px 0;
    padding-top: 20px;
    border-top: 5px solid #f4f4f4;
}
.liCoverImg {
    height: 150px;
    object-fit: cover;
}
.articlsLiBox :global(.ant-card-body) {
    padding: 10px;
}
.articleTitle {
    line-height: 26px;
    height: 52px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
/* 类别 */
.homeChannels {
    margin: 30px 0;
}
.channelsBox {
    margin: 20px 0;
    border-top: 5px solid #f4f4f4;
}
.cateLists {
    white-space: nowrap;
    overflow: hidden;
}
.cateTitle {
    color: #aaa;
    font-size: 18px;
    line-height: 50px;
    padding-right: 50px;
    font-family: 'SimHei';
}
.channelBox span {
    font-size: 18px;
    font-family: 'SimHei';
}
.channelBox {
    position: relative;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin: 5px 0;
    margin-right: 30px;
    cursor: pointer;
}
.channelBox .bottomline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid #f1281e;
    border-radius: 2px;
    width: 30px;
}
/* 视频盒子 */
.eachVideoBox {
    transition: all 0.3s;
}
.eachVideoBox:hover {
    box-shadow: 0 5px 10px 0 rgba(144,144,144,0.3);
}
.coverImgBox {
    position: relative;
    width: 100%;
    height: 160px;
    overflow: hidden;
    cursor: pointer;
}
.coverImgBox>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.playIconBox {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    transition: all 0.3s;
}
.playIconBox img {
    opacity: 0;
    position: absolute;
    height: 150px;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    transition: all 0.3s;
}
.coverImgBox:hover .playIconBox {
    background-color: rgba(0,0,0,0.3);
}
.coverImgBox:hover .playIconBox img {
    opacity: 0.5;
    height: 50px;
}
.videoIntro {
    padding: 10px;
}
.videoIntro h2 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
}
.tagsBox {
    white-space: nowrap;
    overflow: hidden;
    margin: 5px 0;
}
.tag {
    line-height: 30px;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 0 10px;
    margin-right: 5px;
}
/* 专题 */
.homeTopics {
    margin-bottom: 50px;
}
.topicsBox {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 5px solid #f4f4f4;
}
.eachTopicBox {
    border: 1px solid #eee;
    cursor: pointer;
}
.topicCoverBox {
    width: 100%;
    height: 180px;
    overflow: hidden;
    border-right: 1px solid #eee;
}
.topicCoverBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.topicIntroBox {
    padding: 10px;
}
.topicIntroBox h2, .topicIntroBox p{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.topicIntroBox span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
/* 更多 */
.cludemore {
    line-height: 70px;
    text-align: center;
    border: 8px solid #f4f4f4;
    border-radius: 8px;
    font-size: 22px;
    font-family: 'SimHei';
}
.cludemore span {
    cursor: pointer;
}
.cludemore span:hover {
    color: orange;
    text-decoration: underline;
}
.cludemore img {
    margin: 0 0 5px 5px;
}
.cludemore2 {
    padding: 50px 100px 30px 100px;
}
.cludemore2Each {
    text-align: center;

}
.cludemore2Each img {
    height: 100px;
    width: 100px;
}
.cludemore2Each p {
    margin: 10px 0 0 0 ;
}