.historyTitle {
    line-height: 32px;
    padding: 0 20px 0 0;
    font-size: 18px;
}
.videoCoverBox {
    position: relative;
    width: 100%;
    height: 150px;
    cursor: pointer;
}
.videoCoverBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.playIconBox {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    transition: all 0.3s;
}
.playIconBox img {
    opacity: 0;
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    transition: all 0.3s;
}
.videoCoverBox:hover .playIconBox {
    background-color: rgba(0,0,0,0.3);
}
.videoCoverBox:hover .playIconBox img {
    opacity: 0.5;
    height: 50px;
    width: 50px;   
}

.videoDetail h2 {
    margin: 0;
    font-size: 16px;
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}
.videoDetail h2:hover {
    color: #f1281e;
}
.videoDetail p {
    margin: 0;
}

.noDataBox {
    text-align: center;
}
.noDataBox img {
    height: 300px;
}

.paginationBox {
    text-align: center;
}