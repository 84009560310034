.loginBody {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.loginBox {
    width: 438px;
    height: 438px;
    background-color: #fff;
}
.logoHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}
.logoHeader img:nth-child(1) {
    height: 32px;
}
.logoHeader img:nth-child(2) {
    height: 32px;
    cursor: pointer;
}
.loginIptBox {
    padding:20px 50px;
}
.input1, .input2{
    margin-top: 40px;
    border-bottom: 1px solid #ddd;
}
.input1 input, .input2 input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 18px;
}
.otherLoginBox {
    text-align: center;
}
.otherLoginBox h2 {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 20px;
}
.otherLoginBox img {
    width: 48px;
    cursor: pointer;
}