.communityBody {
    width: 980px;
    margin: 20px auto;
}
.findingBox {
    display: inline-block;
    width: 100px;
    text-align: center;
}
.findingBox div{
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
}
.findingBox div img {
    width: 36px;
}
.findingBox span {
    font-size: 16px;
}
.communityBox {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    width: 230px;
    height: 150px;
    border-radius: 10px;
}
.communityBox img {
    width: 120px;

}
.communityBox:nth-child(5){
    margin-right: 0;
}
.followCount {
    position: absolute;
    color: #fff;
    top: 8px;
    left: 8px;
    background-color: rgba(0,0,0,0.5);
    line-height: 24px;
    border-radius: 12px;
    padding: 0 10px;
}
.communityImgBox {
    position: absolute;
    right: 15px;
    top: 15px;
}
.communityNameBox {
    margin: 0;
    position: absolute;
    top: 85px;
    left: 8px;
    color: #fff;
}
.communityArticle {
    position: absolute;
    font-size: 12px;
    color: #fff;
    top: 120px;
    left: 8px;
}
.toJoin {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    line-height: 30px;
    border-radius: 15px;
    padding: 0 10px;
    cursor: pointer;
}
.outJoin {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #fff;
    background-color: rgba(0,0,0,0.3);
    line-height: 30px;
    border-radius: 15px;
    padding: 0 10px;
    cursor: pointer;
}