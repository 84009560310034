.mineBody {
    width: 980px;
    margin: 20px auto;
}
.myCircleBox ul {
    list-style: none;
}
.myCircleBox ul li {
    height: 100px;
    border-bottom: 1px solid #eee;
    box-shadow: 0 2px #eee;
}
.circleImage {
    float: left;
    width: 80px;
    padding-top: 20px;
}
.circleImage img {
    width: 100%;
}
.circleInfoBox {
    float: left;
    padding-top: 15px;
    width: 150px;
}
.circleInfoBox h2 {
    margin: 0;
    font-size: 18px;
}
.circleInfoBox p {
    margin: 0;
    font-size: 13px;
    color: #999;
}
.circleInfoBox span {
    margin: 0;
    font-size: 13px;
    color: #999;
}
.joinCircleImage {
    float: left;
    width: 50px;
    padding-top: 25px;
}
.joinCircleImage img {
    width: 100%;
    cursor: pointer;
}