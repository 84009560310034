/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 面包屑 */
.breadcrumbBox {
    font-size: 16px;
    padding: 25px 0;
    border-bottom: 1px solid #efefef;
}
.breadcrumbBox :global .ant-breadcrumb {
    font-size: 17px;
}
.bookImgBox {
    margin: 15px 0;
}
/* 标题 */
.titleBox h2 {
    line-height: 45px;
    margin: 0;
    padding: 0;
}
.titleBox p{
    margin: 0;
    padding: 0;
}
/* 标签 */
.writername {
    font-size: 16px;
    margin-right: 10px;
    color: #999;
    cursor: pointer;
}
.writername:hover {
    color: #f1281e;
}
.bookTag {
    line-height: 30px;
    border-radius: 15px;
    padding: 0 7px;
    background-color: orange;
    color: #fff;
    margin-right: 5px;
}
/* 作者详情 */
.writerDetailBox {
    overflow: auto;
}
.writerDetailBox h2 {
    text-align: center;
}
.writerDetailBox h2 img {
    height: 30px;
    width: 30px;
    border-radius: 50%; 
    overflow: hidden;
    margin: 0 5px 3px 0;
}
.writerDetailBox p {
    text-indent: 2em;
}
/* 图片 */
.imgBoxLi {
    position: relative;
    height: 250px;
}
.goSingleWord {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    line-height: 30px;
    display: none;
    cursor: pointer;
}
.imgBoxLi:hover .goSingleWord {
    display: block;
}
.imgIndexBox {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    line-height: 30px;
    border-radius: 15px;
    padding: 0 10px;
}
/* 显示大图 */
.bigImgBox {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000;
    width: 100%;
    z-index: 999;
}
.bigImgBox img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-height: 80%;
    max-width: 80%;
}