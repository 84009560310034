/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 面包屑 */
.breadcrumbBox {
    font-size: 16px;
    padding: 25px 0;
    border-bottom: 1px solid #efefef;
}
.breadcrumbBox :global .ant-breadcrumb {
    font-size: 17px;
}
/* 类别筛选 */
.articalCateBox ul {
    list-style: none;
    margin: 10px 0;
}
.articalCateBox ul li {
    position: relative;
    display: inline-block;
    margin: 0 20px;
    line-height: 40px;
    font-size: 16px;
    cursor: pointer;
}
.articalCateBox ul li:hover {
    color: #f1281e;
}
.artCateBottomline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid #f1281e;
    border-radius: 2px;
    width: 30px;
} 
/* 列表每列的样式 */
.articleListBox ul {
    list-style: none;
}
.articleListBox ul li {
    margin-bottom: 15px;
}
.liCoverBox {
    height: 150px;
    width: 200px;
    border-radius: 10px;
    overflow: hidden;
}
.liCoverBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.liIntroBox {
    width: 100%;
}
.liIntroBox h2 {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.liIntroBox h2 span:hover {
    color: #f1281e;
    text-decoration: underline;
    cursor: pointer;
}
.liCountBox {
    margin: 0;
    position: absolute;
    bottom: 10px;
    left: 10px;
    line-height: 20px;
}
.liCountBox span {
    margin-right: 20px;
}
.liCountBox img {
    margin-right: 2px;
    height: 20px;
    vertical-align: top;
}

/* 底部获取更多数据样式 */
.getMoreButton {
    width: 100%;
    line-height: 35px;
    border: 1px solid #eee;
    background-color: #eee;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin: 20px 0;
}
.getMoreButton:hover {
    background-color: #fff;
    color: #04a0d7;
    background-color: #deedf3;
}

/* 右边固定的按钮 */
.fixedButton {
    position: fixed;
    border: 1px solid #aaa;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    bottom: 50px;
    right: 0;
    text-align: center;
    padding-top: 9px;
    cursor: pointer;
}
.fixedButton img {
    width: 30px;
    height: 30px;
}