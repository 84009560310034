
.releaseBox {
    width: 980px;
    margin: 20px auto;
}
/* 每个动态盒子 */
.eachDynamicBox {
    padding-top: 10px;
    border-top: 2px solid #eee;
    width: 980px;
    margin: 0 auto;
    margin-top: 10px;
}
/* 动态  用户头像名字 */
.personBox {
    height: 50px;
    margin-bottom: 15px;
    position: relative;
}
.personAvatarBox, .personName {
    height: 100%;
    float: left;
}
.personAvatarBox {
    margin-right: 15px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
}
.personAvatarBox img {
    height: 100%;
    object-fit: cover;
}
.personName h2 {
    margin: 0;
    padding: 0;
    line-height: 30px;
    font-size: 16px;
}
.personName span {
    line-height: 20px;
    font-size: 13px;
}
.deleteDynamicTag {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: red;
    color: #fff;
    padding: 0 8px;
    line-height: 24px;
    border-radius: 12px;
}
.followUserTag {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: #999;
    color: #fff;
    line-height: 26px;
    height: 26px;
    border-radius: 13px;
    padding: 0 10px;
}
.unFollowUserTag {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: #f9c231;
    color: #fff;
    line-height: 26px;
    height: 26px;
    border-radius: 13px;
    padding: 0 10px;
}
/* 动态内容及图片 */
.contentBox p {
    margin-bottom: 10px;
    font-size: 16px;
}
.eachImgBox {
    width: 100%;
    height: 200px;
}
.eachImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* 评论点赞图标盒子 */
.actionBox {
    margin: 10px 0;
}
.actionBox span {
    margin-left: 30px;
    line-height: 24px;
    font-size: 16px;
}
.actionBox span img {
    height: 20px;
    cursor: pointer;
}
.actionBox span:nth-child(1) img {
    margin: 0 5px 3px 0;
}
/* 评论内容盒子 */
.commentBox {
    background-color: #f6f6f6;
    border-radius: 10px;
}
.commentPersonAvatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
}
.commentPersonAvatar img {
    height: 100%;
    object-fit: cover;
}
.commentContentBox p {
    margin: 0;
    padding: 0;
}
.commentPersonName {
    color: #157c8c;
    cursor: pointer;
    line-height: 30px;
}
.commentPersonName:hover {
    text-decoration: underline;
}
.commentTime {
    line-height: 20px;
    color: #bbb;
}
/* 填写评论 */
.writeCommentBox {
    margin: 10px 0;
}
.writeBoxUnselect {
    border: 1px solid #157c8c;
    line-height: 30px;
    padding-left: 10px;
    cursor: text;
}
.commentDel {
    line-height: 24px;
    font-size: 13px;
    margin-left: 20px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: red;
    color: #fff;
}

/* 新建动态 添加图片  图片样式 */
.newDynamicImgsBox {
    padding: 10px 10px 0 10px;
    border: 1px solid #157c8c;
}
.newDynamicImgsNumber {
    font-size: 16px;
}
.eachNewDynamicImgBox {
    position: relative;
    width: 100%;
    height: 200px;
    border: 1px solid #ddd;
}
.eachNewDynamicImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.deleteImg {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
}
/* 获取更多动态 */
.loadingBox {
    width: 100%;
    margin: 20px 0;
    padding: 0;
    line-height: 30px;
    background-color: #eee;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}
.loadingBox:hover {
    background-color: #fff;
    color: #04a0d7;
    background-color: #deedf3;
}
/* 右边固定的按钮 */
.fixedButton {
    position: fixed;
    border: 1px solid #aaa;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    bottom: 50px;
    right: 20px;
    text-align: center;
    padding-top: 9px;
    cursor: pointer;
}
.fixedButton img {
    width: 30px;
    height: 30px;
}