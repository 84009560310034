/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 面包屑 */
.breadcrumbBox {
    font-size: 16px;
    padding: 25px 0;
    border-bottom: 1px solid #efefef;
}
.breadcrumbBox :global .ant-breadcrumb {
    font-size: 17px;
}

.singleWordBox {
    width: 100%;
}
.wordImgBox {
    text-align: center;
}
.wordImgBox img {
    max-width: 100px;
    max-height: 100px;
}
.singleWordBox p {
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 25px;
    font-size: 16px;
}
/* 分页 */
.paginationBox {
    text-align: center;
    margin-bottom: 20px;
}
/* 显示大图 */
.bigImgBox {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000;
    width: 100%;
    z-index: 999;
}
.bigImgBox img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-height: 80%;
    max-width: 80%;
}