.body {
    overflow: auto;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 888;
    border-bottom: 1px solid #efefef;
    box-shadow: 0 2px 10px 0 rgb(0, 0, 0,0.1);
}
/* 头部样式 */
.headerBox {
    margin: 0 auto;
    height: 100px;
    overflow: hidden;
    /* background-color: red; */
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
.logoImgBox {
    height: 68px;
    margin-top: 16px;
    cursor: pointer;
}
.logoImgBox img {
    height: 100%;
}
.cateBox {
    height: 68px;
    margin-top: 16px;
    text-align: center;
}
.cateBox img {
    vertical-align: middle;
    height: 43px;
    padding: 0 5px 8px 0;
    cursor: pointer;
}
.cateBox span {
    line-height: 68px;
    font-size: 24px;
    cursor: pointer;
}
.circleBox {
    height: 68px;
    margin-top: 16px;
    text-align: center;
    padding: 14px 0 0 0;
}
.circleBox img {
    vertical-align: middle;
    height: 40px;
    cursor: pointer;
}
.searchBox {
    position: relative;
    height: 54px;
    background-color: #fff;
    margin-top: 21px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px 100px 5px 20px;
}
.searchBox input {
    width: 100%;
    font-size: 24px;
    border: none;
    outline: none;
}
.searchIconBox {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 90px;
    height: 54px;
    background-color: red;
    text-align: center;
    padding-top: 9px;
    border-radius: 5px;
}
.searchIconBox img {
    height: 36px;
    width: 36px;
}
.userBox {
    height: 68px;
    width: 100px;
    margin-top: 28px;
    text-align: right;
}
.userBox img {
    vertical-align: middle;
    height: 44px;
    width: 44px;
    border-radius: 22px;
    overflow: hidden;
    object-fit: cover;
    cursor: pointer;
}
.userBox span {
    line-height: 68px;
    font-size: 20px;
    cursor: pointer;
}
.loginButton {
    width: 150px;
    text-align: center;
    margin-top: 25px;
    border: 1px solid #ddd;
    border-radius: 10px;
}
.loginButton>span {
    display: inline-block;
    width: 80px;
    color: #333;
    font-size: 18px;
    line-height: 50px;
}
.headerCenterTitleBox {
    width: 300px;
}
.headerCenterUserImg img {
    height: 60px;
    width: 60px;
    border-radius: 30px;
}
.headerCenterUserIntro {
    padding-left: 10px;
}
.headerCenterUserIntro h2 {
    margin-top: 5px;
    font-size: 14px;
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.headerCenterRight {
    padding-top: 5px;
    text-align: right;
}
.headerCenterRight span {
    cursor: pointer;
    text-align: right;
}
.headerCenterRight span:hover {
    color: #f1281e;
    text-decoration: underline;
}
.headerCenterContent {
    width: 300px;
}
.headerCenterCate {
    width: 100%;
    text-align: center;
    cursor: pointer;
}
.headerCenterCate:hover h2 {
    color: #f1281e;
}
.headerCenterCate img {
    height: 36px;
    width: 36px;
}
.headerCenterCate h2 {
    margin: 0;
    font-size: 14px;
}
.outLogin {
    text-align: right;
    margin-top: 10px;
}
.outLogin span {
    cursor: pointer;
}
.outLogin span:hover {
    color: #f1281e;
}
.headerFenlei ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.headerFenlei ul li {
    line-height: 40px;
    font-size: 18px;
    text-align: center;
    width: 140px;
    cursor: pointer;
    color: #000;
}
.headerFenlei ul li:hover {
    color: #f1281e;
}
