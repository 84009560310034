/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 页子名称 */
.pageFlagBox {
    width: 980px;
    margin: 0 auto;
    margin-top: 15px;
}
.pageFlagBox span {
    margin-right: 20px;
    line-height: 40px;
    font-size: 16px;
    cursor: pointer;
}