/* 底部样式 */
.footerBox {
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 250px;
    text-align: center;
    padding-top: 30px;
    overflow: hidden;
}
.footerBox dl {
    display: inline-block;
    width: 200px;
    text-align: center;
}
.footerBox dl dt {
    font-size: 18px;
    padding-bottom: 10px;
    cursor: pointer;
}

.footerBox dl dd {
    color: #bbb;
    cursor: pointer;
}