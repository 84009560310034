.bigImgBody {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 700px;
    background-color: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.bigImgBox {
    position: relative;
    background-color: rgba(0,0,0,0.9);
    width: 980px;
    min-height: 300px;
}
.deleteImg {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 2;
}
.actImgBox {
    width: 100%;
    position: relative;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.actImgBox>img {
    max-width: 100%;
    max-height: 100%;
}
.actImgBox:hover .rotateBox  {
    opacity: 1;
}
.actImgBox:hover .beforeImgBox  {
    opacity: 1;
}
.actImgBox:hover .nextImgBox  {
    opacity: 1;
}
.rotateBox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(0,0,0,0.3);
    padding-top: 10px;
    text-align: center;
    opacity: 0;
    transition: all 0.5s;
}
.rotateBox img {
    height: 30px;
}
.imgListBox {
    text-align: center;
    padding-top: 15px;
}
.imgListBox img {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    margin-right: 5px;
    object-fit: cover;
}
.imgListBox img:last-child {
    margin-right: 0;
}
.beforeImgBox {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;
}
.beforeImgBox img {
    width: 40px;
    cursor: pointer;
}
.nextImgBox {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;
}
.nextImgBox img {
    width: 40px;
    cursor: pointer;
}