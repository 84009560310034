/* 版心 */
.container {
    margin: 0 auto;
    /* overflow: hidden; */
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 讲师介绍 */
.teacherPersonBox {
    padding: 20px 0;
    border-bottom: 1px solid #efefef;
}
.teacherImgBox {
    height: 400px;
    width: 400px;
}
.teacherImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
}
.teacherIntroBox {
    padding: 0 20px;
    user-select: text
}
.teacherIntroTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.teacherIntroTitle h2 {
    font-weight: 600;
    margin: 0;
}
.teacherIntroTitle h2 span {
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
    color: #999;
}
.noconcerns {
    background-color: #f9c231;
    color: #fff;
    line-height: 26px;
    height: 26px;
    border-radius: 13px;
    padding: 0 10px;
}
.isconcerns {
    background-color: #999;
    color: #fff;
    line-height: 26px;
    height: 26px;
    border-radius: 13px;
    padding: 0 10px;
}
/* .teacherIntroDet {
    position: relative;
    text-indent: 2em;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    user-select: text;
} */
.teacherIntroDet {
    position: relative;
    text-indent: 2em;
    height: 350px;
    user-select: text;
    overflow: auto;
    margin: 0;
}

/* 推荐视频 */
.tuijianArt {
    margin: 15px 0;
}
.tuijianArt>h2 {
    margin: 0;
    line-height: 50px;
}
/* 视频盒子 */
.borderBox {
    border: 1px solid #eee;
}
.borderBox:hover {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}
.videoImgBox {
    position: relative;
    height: 150px;
    width: 100%;
}
.videoImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.playIconBox {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    transition: all 0.3s;
}
.playIconBox img {
    opacity: 0;
    position: absolute;
    height: 150px;
    width: 150px;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    transition: all 0.3s;
}
.videoImgBox:hover .playIconBox {
    background-color: rgba(0,0,0,0.3);
}
.videoImgBox:hover .playIconBox img {
    opacity: 0.5;
    height: 50px;
    width: 50px;
}
.videoIntro {
    padding: 0 15px;
}
.videoIntro h2 {
    line-height: 50px;
    margin: 0;
    font-family: 'SimHei';
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.videoIntro p {
    margin-bottom: 5px;
    line-height: 22px;
    height: 44px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.tagsBox {
    overflow: hidden;
    white-space: nowrap;
}
.tagsBox span {
    line-height: 26px;
    padding: 0 5px;
    border-radius: 13px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-right: 5px;
}
.videoIntro>span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.paginationBox {
    text-align: center;
    margin-bottom: 20px;
}

