/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 面包屑 */
.breadcrumbBox {
    font-size: 16px;
    padding: 25px 0;
    border-bottom: 1px solid #efefef;
}
.breadcrumbBox :global .ant-breadcrumb {
    font-size: 17px;
}