/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 面包屑 */
.breadcrumbBox {
    font-size: 16px;
    padding: 25px 0;
    border-bottom: 1px solid #efefef;
}
.breadcrumbBox :global .ant-breadcrumb {
    font-size: 17px;
}
/* 字帖类别 */
.bookCateBox {
 margin: 0 0 20px 0;
}
.bookCateBox span {
    line-height: 30px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 15px;
}
/* 字帖盒子样式 */
.bookImgBox {
    height: 200px;
    width: 100%;

}
.bookImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}
.bookBox h2 {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}
.bookBox h2:hover {
    color: #f1281e;
}
.bookWriter {
    margin: 0;
    padding: 0;
    color: #999;
    cursor: pointer;
}
.bookWriter span:hover {
    color: orange;
}
.bookTagBox {
    white-space: nowrap;
    overflow: hidden;
}
.bookTagBox span {
    line-height: 30px;
    border-radius: 15px;
    padding: 0 7px;
    background-color: orange;
    color: #fff;
    margin-right: 5px;
}
/* 分页 */
.paginationBox {
    text-align: center;
    margin-bottom: 20px;
}
/* 作者详情 */
.writerDetailBox {
    overflow: auto;
}
.writerDetailBox h2 {
    text-align: center;
}
.writerDetailBox h2 img {
    height: 30px;
    width: 30px;
    border-radius: 50%; 
    overflow: hidden;
    margin: 0 5px 3px 0;
}
.writerDetailBox p {
    text-indent: 2em;
}