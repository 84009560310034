.body {
    overflow: auto;
}
/* 版心 */
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 1500px;
    min-width: 980px;
}
/* 面包屑 */
.breadcrumbBox {
    font-size: 16px;
    padding: 25px 0;
    border-bottom: 1px solid #efefef;
}
.breadcrumbBox :global .ant-breadcrumb {
    font-size: 17px;
}
/* 视频盒子 */
/* 标题部分 */
.videoTitilBox {
    padding: 20px 0 10px 0;
    margin-top: 20px;
}
.videoTitilBox h2 {
    height: 30px;
}
.channelTag {
    display: block;
    float: left;
    font-size: 15px;
    line-height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    background-color: #ffafc8;
    color: #fff;
    margin-right: 10px;
}
.videoTitle {
    max-width: 25em;
    display: block;
    float: left;
    line-height: 30px;
    font-weight: 600;
    font-size: 26px;
    font-family: 'SimHei';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.goodAndFav {
    padding-left:10px;
}
.goodAndFav img {
    height: 30px;
    margin-right: 15px;
}
.videoTitilBox p {
    margin: 0;
    padding: 0;
    height: 25px;
}
.videoTitilBox p span {
    display: block;
    margin-right: 15px;
    float: left;
}
.videoTitilBox p span img {
    width: 14px;
    margin: 0 5px 2px 0;
}
.playvideoIntro {
    display: inline-block;
    max-width: 500px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.videoTeacherBox {
    margin-top: 20px;
}
.teacherImg {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
}
.teacherImg img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.teacherIntro {
    padding: 0 10px;
}
.teacherIntro h2 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 3px;
    font-size: 15px;
    color: #ffafc8;
}
.teacherIntro p {
    margin-bottom: 3px;
    color: #aaa;
}
.noconcerns {
    display: inline-block;
    line-height: 24px;
    padding: 0 15px;
    border-radius: 5px;
    color: #ffafc8;
    border: 1px solid #ffafc8;
    cursor: pointer;
}
.isconcerns {
    display: inline-block;
    line-height: 24px;
    padding: 0 15px;
    border-radius: 5px;
    color: #fff;
    background-color: #ccc;
    cursor: pointer;
}

/* 视频部分 */
.videoBody {
    width: 100%;
    height: 500px;
    overflow: hidden;
}
.playVideoBox {
    position: relative;
    width: 100%;
    height: 500px;
    background: #000;
}
.playVideo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.playIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3);
}
.playIcon img {
    height: 150px;
    width: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
}
.payContinuePlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    text-align: center;
    padding-top: 150px;
}

/* 专题列表 */
.topicsBox {
    width: 100%;
    height: 500px;
    overflow: auto;
    background: #eee;
    padding: 10px;
}
.topicsBox ul {
    list-style: none;
    margin: 0;
}
.topicsBox ul li {
    margin-top: 10px;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
}
.normalLi {
    color: #000;
}
.normalLi:hover {
    background-color: #fff;
    color: #04a0d7;
}
.actLi {
    background-color: #fff;
    color: #04a0d7;
}
.topicsTitle {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.timeLength {
    font-size: 12px;
}

/* 视频盒子 */
.eachVideoBox {
    transition: all 0.3s;
}
.eachVideoBox:hover {
    box-shadow: 0 5px 10px 0 rgba(144,144,144,0.3);
}
.coverImgBox {
    position: relative;
    width: 100%;
    height: 160px;
    overflow: hidden;
    cursor: pointer;
}
.coverImgBox>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.playIconBox {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    transition: all 0.3s;
}
.playIconBox img {
    opacity: 0;
    position: absolute;
    height: 150px;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    transition: all 0.3s;
}
.coverImgBox:hover .playIconBox {
    background-color: rgba(0,0,0,0.3);
}
.coverImgBox:hover .playIconBox img {
    opacity: 0.5;
    height: 50px;
}
.videoIntro {
    padding: 10px;
}
.videoIntro h2 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
}
.tagsBox {
    white-space: nowrap;
    overflow: hidden;
    margin: 5px 0;
}
.tag {
    line-height: 30px;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 0 10px;
    margin-right: 5px;
}

 /* 推荐视频盒子 */
.videoRecommentsBox {
    margin-top: 20px;
}
.videoCommentsBox {
    margin-top: 20px;
}
/* 评论 */
.commentBox {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}
.commentUserImgBox {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
}
.commentUserImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.commentDetail {
    padding: 0 20px;
}
.commentDetail h2 {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    margin: 0;
}
.commentDetail p {
    min-height: 30px;
    line-height: 28px;
    font-size: 17px;
    margin: 0;
}
.moreComments {
    width: 100%;
    margin: 20px 0;
    padding: 0;
    line-height: 30px;
    background-color: #eee;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}
.moreComments:hover {
    background-color: #fff;
    color: #04a0d7;
    background-color: #deedf3;
}
.commentDel {
    line-height: 24px;
    font-size: 13px;
    margin-left: 20px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: red;
    color: #fff;
}